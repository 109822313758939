import Axios, { AxiosResponse } from 'axios';
import { IIndividualRewardAuditHistory, IndividualId } from 'model/individual';

const apiUrl = window.REACT_APP_BASE_API_URI;
export function findIndividualById(id:string) {
  return Axios.get(`${apiUrl}/individual/v2/find/${encodeURIComponent(id)}`);
}

export function findAllIndividuals() {
  return Axios.get(`${apiUrl}/individual/v2/findAll`);
}

export function generateLaunchLeaderReferralLink(individualId:string, amount:number) {
  return Axios.post(`${apiUrl}/individual/v2/generateLaunchLeaderReferralLink/${encodeURIComponent(individualId)}`, { amount });
}

export function generateServiceProviderNeighborReferralLink(individualId:string, amount:number) {
  return Axios.post(`${apiUrl}/individual/v2/generateServiceProviderNeighborReferralLink/${encodeURIComponent(individualId)}`, { amount });
}

export function generateRefereeGiftReferralLink(individualId: string, amount: number, mc: string) {
  return Axios.post(`${apiUrl}/individual/v2/generateRefereeGiftReferralLink/${encodeURIComponent(individualId)}`, { amount, marketingCode: mc });
}

export function updateLaunchLeaderStatus(individualId:string, status:string) {
  return Axios.post(`${apiUrl}/individual/v2/updateLaunchLeaderStatus/${encodeURIComponent(individualId)}`, { status });
}

export function getSelfRoles():Promise<AxiosResponse<any>> {
  return Axios.get(`${apiUrl}/individual/v2/getSelfRoles`);
}

export function getIndividualRewardsAuditHistory(individualId:string):Promise<AxiosResponse<Array<IIndividualRewardAuditHistory>>> {
  return Axios.get(`${apiUrl}/individual/v2/getIndividualRewardsAuditHistory/${encodeURIComponent(individualId)}`);
}